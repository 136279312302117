@use '../utils/_variables' as *

.legal
    width: 500px
    max-width: 90vw
    min-width: 50vw
    margin: 0 auto
    padding-bottom: 30px
    & a
        font-weight: bold
        color: var(--text-low)
    & h2 
    & h3
        text-align: center