@use '../utils/_variables' as *

.footer
    height: $footerHeight
    background-color: var(--light-text-high)
    color: var(--light-background)
    display: flex
    justify-content: space-around
    align-items: center
    overflow: hidden
    @media (max-width: $mobileSize)
        height: fit-content
        display: grid
        grid-template-columns: 1fr
        gap: 40px
    &__contact
        width: 250px
        height: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        color: var(--light-background)
        @media (max-width: $mobileSize)
            width: 100%
            height: fit-content
            grid-row: 2/3
            flex-direction: row
            gap: 15px
        &__icon
            font-size: 30px
    &__name
        width: 200px
        height: 150%
        background-color: var(--background)
        color: var(--text-high)
        border-radius: 50%
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        gap: 20px
        @media (max-width: $mobileSize)
            width: 100%
            height: fit-content
            padding: 25px 0
            grid-row: 1/2
            border-radius: 0
            border-bottom: 1px solid var(--light-background)
            background-color: var(--light-text-high)
            color: var(--light-background)
        &__icon
            font-size: 45px
        &__title
            margin: 0
            font-size: 20px
            text-align: center
    &__legal
        width: 250px
        height: 100%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        color: var(--light-background)
        @media (max-width: $mobileSize)
            width: 100%
            height: fit-content
            grid-row: 3/4
            flex-direction: row
            gap: 15px
        & svg
            font-size: 30px
