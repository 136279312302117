@use '../utils/_variables' as *

.bubble
    position: relative
    max-width: 500px
    display: flex
    & svg
        width: 100%
        height: 100%
        position: absolute
    & p
        text-align: justify
        position: relative
        font-size: 20px
        // font-family: 'Dancing Script', 'Lato'
        font-family: 'Lato'
        font-style: italic
    &--right
        align-self: end
        & svg
            transform: scale(-1,1)
            fill: var(--text-high)
            @media (max-width: $mobileSize)
                transform: scale(1,1)
        & p
            padding: 5px 15% 35px 5%
            color: var(--background)
            @media (max-width: $mobileSize)
                padding: 5px 5% 25px 5%
    &--left
        align-self: start
        & svg
            fill: var(--background-subtle)
            stroke: var(--text-high)
            stroke-width: 2px
        & p
            padding: 1% 7% 5% 13%
            color: var(--text-high)
            @media (max-width: $mobileSize)
                padding: 1% 7% 30px 5%




// .bubble
    
//     padding: 15px
//     margin-top: 50px
//     margin-bottom: 50px
//     border-radius: 10px 20px
//     display: flex
//     flex-direction: column
//     align-items: center
//     & p
//         text-align: justify
//     & div
        
//     &--right
//         background-color: var(--text-high)
//         color: var(--background)
//         & div
//             background-color: var(--text-high)
//             position: relative
//             right: -30px
//             bottom: -25px
//             width: 60px
//             height: 35px
//             align-self: end
//             border-radius: 0 0 0 100%
//             clip-path: polygon(0 0, 60% 0, 100% 100%, 0 100%)
//             transform: rotate(25deg)
//     &--left 
//         background-color: var(--background-subtle)
//         color: var(--text-high)
//         & div
//             background-color: var(--background-subtle)
//             position: relative
//             left: -35px
//             bottom: -25px
//             width: 60px
//             height: 35px
//             align-self: start
//             border-radius: 0 0 100% 0
//             clip-path: polygon(50% 0, 100% 0, 100% 100%, 0 100%)
//             transform: rotate(-25deg)