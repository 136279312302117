@use '../utils/_variables' as *

.header
    width: 95%
    height: $headerHeight
    padding: 0 2.5%
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    border-bottom: 1.5px solid var(--text-high)
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px
    position: sticky
    top: 0
    z-index: 1
    background-color: var(--background)
    @media (max-width: $tabletSize)
        flex-direction: column
        align-items: center
        height: fit-content
        padding-bottom: 20px
        border: none
    &__title
        display: flex
        flex-direction: row
        align-items: center
        gap: 20px
        color: var(--text-high)
        &__icon
            font-size: 45px
        &__name
    &__nav
        display: flex
        gap: 25px
        @media (max-width: $mobileSize)
            flex-direction: column
            align-items: center
        &__button
            // width: 320px
            display: flex
            align-items: center
            justify-content: center
            padding: 10px 60px
            border-radius: 10px
            font-weight: 500
            font-size: 16px
            border: none
            transition: 0.2s ease-in-out
            @media (max-width: $mobileSize)
                width: 230px
                padding: 10px 0
            &:hover
                cursor: pointer
            &--dark
                background-color: var(--text-high)
                color: var(--background)
                &:hover
                    background-color: var(--text-low)
            &--light
                background-color: var(--element)
                color: var(--text-high)
                &:hover
                    background-color: var(--element-hovered)
    &__themeMode
        position: absolute
        right: 25px
        top: calc($headerHeight + 25px)
        cursor: pointer