@use '../utils/_variables' as *


//    GLOBAL MODAL STYLE

.modal
    display: none
    width: 100vw
    height: 100vh
    z-index: 1000
    position: fixed
    top: 0
    left: 0
    background-color: rgba(0,0,0,0.8)
    justify-content: center
    align-items: center
    
    &__content
        width: 50%
        height: fit-content
        max-height: 95vh
        min-height: 200px
        background-color: var(--background)
        border-radius: 8px
        display: flex
        flex-direction: column
        align-items: center
        padding: 20px
        overflow: scroll
        @media (max-width: $tabletSize)
            position: fixed
            top: 0
            width: calc( 100vw - 40px )
            // height: calc( 100vh - 40px)
            height: calc( 100vh - 40px - var(--mobile-menu-height))
            border-radius: initial
            transition: 0.2s ease-in-out
    &__closeButton
        position: fixed
        align-self: end
        font-size: 25px
        &:hover
            cursor: pointer


.v2modal
    display: flex
    width: 100vw
    height: 100vh
    z-index: 1000
    position: fixed
    top: 0
    left: 0
    background-color: rgba(0,0,0,0.8)
    justify-content: center
    align-items: center
    
    &__content
        width: 50%
        height: fit-content
        max-height: 95vh
        min-height: 200px
        background-color: var(--background)
        border-radius: 8px
        display: flex
        flex-direction: column
        align-items: center
        padding: 20px
        overflow: scroll
        @media (max-width: $tabletSize)
            position: fixed
            top: 0
            width: calc( 100vw - 40px )
            // height: calc( 100vh - 40px)
            height: calc( 100vh - 40px - var(--mobile-menu-height))
            border-radius: initial
            transition: 0.2s ease-in-out
    &__closeButton
        position: fixed
        align-self: end
        font-size: 25px
        &:hover
            cursor: pointer



//    NEW TRANSFER    

.newTransferModal
    &__content
        overflow-y: scroll
        height: initial
    &__form
        gap: 10px
        & input
            width: calc( 100% - 40px )
        & select
            width: 100%
        & button
            background-color: var(--text-high)
            color: var(--background)
            &:hover
                background: var(--text-low)
                color: var(--background-subtle)


//  PAYMENT PLAN   

.paymentPlanModal
    &__content
        height: fit-content
        max-height: 90vh
        min-height: 50vh
    &__button
        color: white
        font-size: 16px
        font-weight: 500
        padding: 10px 20px
        border-radius: 5px
        cursor: pointer
        &--openForm
            background-color: var(--success)
            &:hover
                background-color: var(--success-hovered)
        &--exitForm
            background-color: var(--danger)
            &:hover
                background-color: var(--danger-hovered)
    &__table
    &__addForm
        gap: 10px
        & input
            width: calc(100% - 40px)
        & select
            width: 100%
        &__endPlan
            width: 100%
            margin: 20px 0
            padding: 10px 0
            background-color: var(--background-subtle)
            display: flex
            flex-direction: column
            gap: 10px
            border-radius: 8px
            & p
                text-align: center
                font-weight: bold
            &__choose
                width: 100%
                display: flex
                justify-content: center
                align-items: center
                gap: 5px
                &__toggle
                    width: 30px
                    height: 11px
                    padding: 5px
                    border-radius: 13px
                    border: 1px solid var(--text-high)
                    display: flex
                    flex-direction: column
                    justify-content: center
                    // align-items: start
                    cursor: pointer
                    & div
                        width: 12px
                        height: 12px
                        position: relative
                        left: calc( 100% - 12px )
                        border-radius: 50%
                        background-color: var(--text-high)
                        transition: .2s ease-in-out
        & button
            background-color: var(--text-high)
            color: var(--background)
            &:hover
                background-color: var(--text-low)
        
    &__updateForm
        width: 100%
        gap: 10px
        & input
            width: calc( 100% - 40px )
        & select
            width: 100%
        & button
            background-color: var(--text-high)
            color: var(--background)
            &:hover
                background-color: var(--text-low)

.subcategoryModal
    &__content
        height: fit-content
        max-height: 95vh
    &__form 
        & label
            width: 100%
            margin-top: 15px
        & input
            width: calc( 100% - 40px)
        & select
            width: 100%
        & button
            background-color: var(--text-high)
            color: var(--background)
            padding: 10px 40px
            &:hover
                background-color: var(--text-low)

.updateSubcategoryModal
    &__content
        max-height: 95vh
        height: fit-content
    &__selectChoice
        width: 50%
        display: flex
        flex-direction: column
        align-items: center
        background-color: var(--background-subtle)
        margin: 20px 0
        padding: 10px 0
        border-radius: 8px
        &__choose
            width: 100%
            display: flex
            justify-content: center
            align-items: center
            gap: 5px
            &__toggle
                width: 30px
                height: 11px
                padding: 5px
                border-radius: 13px
                border: 1px solid var(--text-high)
                display: flex
                flex-direction: column
                justify-content: center
                // align-items: start
                cursor: pointer
                & div
                    width: 12px
                    height: 12px
                    position: relative
                    left: calc( 15px - 6px )
                    border-radius: 50%
                    background-color: var(--text-high)
                    transition: .2s ease-in-out
    & form
        & label
            width: 100%
            margin-top: 15px
        & input
            width: calc( 100% - 40px)
        & select
            width: 100%
        & button
            background-color: var(--text-high)
            color: var(--background)
            padding: 10px 40px
            &:hover
                background-color: var(--text-low)
    &__update
        display: flex
        flex-direction: column 
        align-items: center
    &__delete
        display: flex
        flex-direction: column 
        align-items: center 

.selectSubcategoryModal
    &__content
        max-height: 90dvh
        height: fit-content
        &__title
            font-weight: bold
            font-size: 20px
        &__list
            display: flex
            flex-direction: column
            gap: 10px
            & div
                cursor: pointer
                &:hover
                    color: var(--text-low)
        & button
            padding: 5px 15px
            margin-top: 15px
            background-color: var(--success)
            color: var(--background)
            font-size: 16px
            border: none
            border-radius: 5px
            &:hover
                background-color: var(--success-hovered)


.accountParametersModal
    &__button
        transition: 0.2s ease-in-out
        border: none
        border-radius: 5px
        padding: 10px 15px
        font-size: 14px
        font-weight: bold
        cursor: pointer
        display: flex
        justify-content: center
        align-items: center
    &__content
    &__logo
        margin-bottom: 15px
        &__view
            width: 150px
            height: 150px
            margin-bottom: 15px
            display: flex
            flex-direction: column
            align-items: center
            gap: 5px
            & svg 
                transition: 0.2s ease-in-out
                color: var(--danger)
                cursor: pointer
                &:hover
                    color: var(--danger-hovered)
            & img 
                width: 100%
                height: 100%
                object-fit: contain
        &__add
            // font-weight: 500
            background-color: var(--success)
            color: white
            &:hover
                background-color: var(--success-hovered)
            & input
                display: none
    &__bankForm
        &__cancel
            padding: 10px 20px
            font-size: 14px
            background-color: var(--danger)
            color: white
            margin-bottom: 15px
            &:hover
                background-color: var(--danger-hovered)
        &__submit
            padding: 10px 30px
            background-color: var(--text-high)
            color: var(--background)
            &:hover
                background-color: var(--text-low)
    &__bank
        display: flex
        flex-direction: column
        align-items: center
        &__table
            margin-bottom: 10px
            & td 
                padding: 5px
            & td:first-child
                font-weight: bold
        &__updateButton
            background-color: var(--success)
            color: white
            &:hover
                background-color: var(--success-hovered)
    &__archive
        &__description
            text-align: justify
        &__button
            background-color: var(--warning)
            &:hover
                background-color: var(--warning-hovered)
    &__delete
        &__button
            background-color: var(--danger)
            color: white 
            &:hover
                background-color: var(--danger-hovered)

.deleteUserModal
    &__content
    &__description
        text-align: justify
    &__choice
        display: flex
        gap: 20px 
        &__button
            transition: 0.2s ease-in-out
            padding: 7px 12px
            border: none
            border-radius: 5px
            cursor: pointer
            color: white
            &--yes
                background-color: var(--success)
                &:hover
                    background-color: var(--success-hovered)
            &--no
                background-color: var(--danger)
                &:hover
                    background-color: var(--danger-hovered)