@use '../utils/_variables' as *

.verifyEmail
    &__content
        display: flex
        flex-direction: column
        align-items: center
        margin: 20px 0 40px 0
        &__circle 
            position: relative
            width: 150px
            height: 150px
            display: flex
            justify-content: center
            align-items: center
            & svg
                position: absolute
            &__background 
                stroke-width: 9
                stroke: var(--text-high)
                // stroke: var(--text-high)
            &__progress 
                // stroke: #4caf50
                stroke: #e6e6e6
                stroke-width: 10
                stroke-linecap: round
                transition: stroke-dashoffset 0.35s
                transform: rotate(-90deg)
                transform-origin: 50% 50%
            &__content
                width: calc( 100% - 18px )
                height: calc( 100% - 18px )
                display: flex
                align-items: center
                justify-content: center
                position: absolute
                border-radius: 50%
                &--wait
                    font-size: 24px
                    // background-color: var(--text-high)
                &--resend
                    // background-color: var(--element)
                    cursor: pointer
                    font-size: 18px
                    transition: 0.2s ease-in-out
                    &:hover
                        font-weight: 500
                        font-size: 19px
                        transition: 0.2s ease-in-out
                        // background-color: var(--element-hovered)