@use '../utils/_variables' as *

.dashboard 
    transition: 0.2s ease-in-out
    // width: calc( 100% - $dashboardMenuWidth - 50px) // 50px is padding
    width: calc( 100vw - var(--dashboard-menu-width) - 50px) // 50px is padding
    height: calc( 100vh - $dashboardHeaderHeight - 15px - 50px ) // 15px for the header margin and 50 for the dashboard padding

    position: fixed
    overflow-y: scroll

    right: 0
    bottom: 0
    padding: 25px
    // padding: 0 3% 0 calc( var(--dashboard-menu-width) + 3%)
    // margin-top: calc( $dashboardHeaderHeight + 20px)

    // background: linear-gradient(145deg, var(--background) 0%, var(--background-subtle) 50%, var(--background) 100%)
    @media (max-width: $mobileSize)
        padding: 0
        bottom: 50px
        margin: 0 2vw
        width: calc( 96vw)
        height: calc( 100vh - $dashboardHeaderHeight - 50px)
        // height: calc( 100vh - $dashboardHeaderHeight - var(--mobile-menu-height))
        scrollbar-width: none
        &::-webkit-scrollbar
            display: none
