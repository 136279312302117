@use '../utils/_variables' as *

.settings
    display: flex
    flex-direction: column
    align-items: center
    padding-bottom: 15px
    &__title
        text-align: center
    &__subtitle
        text-align: center
        & svg 
            cursor: pointer
    &__updatePasswordBtn
        transition: 0.2s ease-in-out
        border: none
        border-radius: 5px
        padding: 5px 20px
        background-color: var(--warning)
        color: black
        cursor: pointer
        font-size: 16px
        &:hover
            background-color: var(--warning-hovered)
    &__archivedAccount
        display: flex
        align-items: center
        gap: 15px
        & button
            transition: 0.2s ease-in-out
            border: none
            border-radius: 5px
            padding: 5px 10px
            background-color: var(--success)
            color: white
            cursor: pointer
            font-size: 15px
            &:hover
                background-color: var(--success-hovered)
    &__mode
        display: flex
        gap: 15px
        cursor: pointer
        margin: 15px 0
        &__toggle
            height: 10px
            width: 30px
            border-radius: 10px
            padding: 5px
            display: flex
            flex-direction: column
            border: 1px solid var(--text-high)
            & div
                position: relative
                left: 0
                width: 10px
                height: 10px
                transition: 0.2s ease-in-out
                align-self: start
                border-radius: 50%
                background-color: var(--text-high)

    &__colors
        display: grid
        // flex-direction: row
        // justify-content: space-around
        // flex-wrap: wrap
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 
        row-gap: 30px
        width: 50%
        min-width: 650px
        @media (max-width: $tabletSize)
            min-width: initial
            width: 100%
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr
        @media (max-width: $mobileSize)
            grid-template-columns: 1fr 1fr 1fr
        &__item
            display: flex
            flex-direction: column
            align-items: center
            &__square
                width: 30px
                height: 30px
                border-radius: 5px
                transition: 0.2s ease-in-out
                display: flex
                justify-content: end
                padding: 2.5px
                &:hover
                    width: 35px
                    height: 35px
                    margin: -5px
                    padding: 5px
                    cursor: pointer
                    
            &__text
                text-align: center
            &__check
                // color: var(--success)
                filter: invert(1)
    &__danger__deleteButton
        transition: 0.2s ease-in-out
        border: none
        border-radius: 5px
        padding: 5px 20px
        background-color: var(--danger)
        color: white
        cursor: pointer
        font-size: 16px
        &:hover
            background-color: var(--danger-hovered)