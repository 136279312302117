@use '../utils/_variables' as *

.tickets
    display: flex
    flex-direction: column
    align-items: center
    &__description
    &__addButton
        width: fit-content
        // align-self: center
        padding: 7px 30px
        border: none
        background-color: var(--success)
        color: white
        border-radius: 5px
        font-size: 16px
        transition: 0.2s ease-in-out
        margin: 25px
        &:hover
            background-color: var(--success-hovered)
            cursor: pointer
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px
    &__title
        text-align: center
    &__container
        width: 100%
        height: fit-content
        display: flex
        flex-direction: row
        justify-content: start
        align-items: start
        flex-wrap: wrap
        gap: 10px
        @media (max-width: $mobileSize)
            padding-bottom: 15px
            justify-content: center
        &__one
            width: 200px
            height: 300px
            display: flex
            flex-direction: column
            align-items: center
            gap: 5px
            &__timeLeft
                text-align: center
            &__img
                width: 100%
                height: 100%
                object-fit: contain
            &__delete
                // width: 50%
                align-self: center
                border: none
                ouline: none
                border-radius: 5px
                background-color: var(--danger)
                color: white
                font-size: 14px
                padding: 5px 25px
                transition: 0.2s ease-in-out
                &:hover
                    background-color: var(--danger-hovered)
                    cursor: pointer
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px


// New Ticket Modal

.newTicketModal
    &__content
        min-width: fit-content
        width: 50%
        height: fit-content
        padding: 25px

.newTicketForm
    width: 90%
    &__preview 
        min-height: 250px
        height: 50vh
        width: 100%
        display: flex
        justify-content: center
        align-items: center
        margin-bottom: 30px
        padding: 10px
        border-radius: 5px
        background-color: var(--background-subtle)
        cursor: pointer
        &__img
            object-fit: contain
            width: 100%
            height: 100%
        &__noImage
            display: flex
            flex-direction: column
            align-items: center
            &__icon
                font-size: 50px
            &__text
    &__deleteButton
        color: white
        background-color: var(--danger)
        cursor: pointer
        padding: 5px 20px
        border-radius: 5px
        font-size: 14px
        transition: 0.2s ease-in-out
        &:hover
            background-color: var(--danger-hovered)
    & label 
        display: flex
        flex-direction: column 
        align-items: center
        & span
            // background-color: var(--text-high)
            // color: var(--background)
            // cursor: pointer
            // padding: 10px 40px
            // border-radius: 5px
            // &:hover
            //     background-color: var(--text-low)
        & input
            display: none
    & button
        background-color: var(--text-high)
        color: var(--background)
        cursor: pointer
        padding: 10px 40px
        border-radius: 5px
        font-size: 16px
        align-self: center
        &:hover
            background-color: var(--text-low)