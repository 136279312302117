@use '../utils/_variables' as *

.register
    // height: calc( 100vh - $footerHeight - $headerHeight ) 
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 60px
    

.registerForm
    width: fit-content
    display: flex
    flex-direction: column 
    align-items: center
    // gap: 15px
    // margin: auto 0
    padding: 20px 0
    &__bloc
        display: flex
        flex-direction: column
    & label
        align-self: start
        position: relative
        top: -32px
        left: 20px
        margin-bottom: 15px
        transition: 0.3s ease-in-out
    & input
    & input:focus + label, input:not(:placeholder-shown) + label
        transform: translateY(-33px)
    & input:hover:placeholder-shown:not(:focus) + label
        cursor: text
    &__conditions
        max-width: 60%
        display: flex
        // flex-direction: row
        align-items: center
        justify-content: end
        flex-wrap: nowrap
        margin-top: -10px
        & input 
            cursor: pointer
            border: 1px solid var(--border)
            height: 25px
            width: 25px
        & label
            align-self: initial
            position: initial
            margin: 0
            & a
                color: var(--text-low)
    &__error
        position: relative
        top: -30px
    &__disabledButton
        opacity: 0.5
        &:hover
            cursor: not-allowed