@use '../utils/_variables' as *

.forgottenPassword
    display: flex
    flex-direction: column 
    justify-content: center
    &__content
        max-width: 500px
        display: flex
        flex-direction: column
        align-items: center
        margin: auto
        padding: 45px
        & p
            font-weight: 500
    & form
        & input
            max-width: 100%
            width: 250px