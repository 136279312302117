@use '../utils/_variables' as *

form
    display: flex
    flex-direction: column 
    justify-content: center
    align-items: center
    & input:-webkit-autofill,& textarea:-webkit-autofill,& select:-webkit-autofill 
        background-color: var(--success) !important
    & input:-moz-autofill,& textarea:-moz-autofill,& select:-moz-autofill 
        background-color: var(--success) !important
    & input:-ms-input-placeholder,& textarea:-ms-input-placeholder,& select:-ms-input-placeholder 
        background-color: var(--success) !important

    & input
        color: var(--text-high)
        outline: none
        border: 0.5px solid var(--element)
        border-radius: 5px
        padding: 10px 20px
        font-size: 16px
        background-color: var(--element)
        transition: 0.2s ease-in-out
        &:focus
            // border: 0.5px solid var(--border-selected)
            background-color: var(--background-subtle)
    & select
        color: var(--text-high)
        background-color:  var(--element)
        border-radius: 5px
        outline: none
        border: none
        padding: 10px 20px
        font-size: 16px
    & textarea
        height: fit-content
        resize: vertical
        color: var(--text-high)
        background-color:  var(--element)
        border-radius: 5px
        outline: none
        border: none
        padding: 10px 20px
        font-size: 16px
        &:focus
            background-color: var(--background-subtle)
    & button
        margin-top: 25px
        outline: none
        border: none
        border-radius: 5px
        background-color: var(--element)
        color: var(--text-high)
        padding: 15px 40px
        font-size: 18px
        font-weight: bold
        transition: 0.2s ease-in-out
        &:hover
            cursor: pointer
            background-color: var(--element-hovered)
        &:active
            background-color: var(--element-selected)
            
