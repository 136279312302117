@use '../utils/_variables' as *

.resetPassword
    &__content
        display: flex
        flex-direction: column
        align-items: center
        margin: auto
    & form
        & input
            margin-bottom: 30px
