@use '../utils/_variables' as *

.contact
    width: 500px
    max-width: 90vw
    min-width: 50vw
    margin: 0 auto
    padding-bottom: 30px
    &__description
        text-align: justify
    & form
        & input
            // width: calc(100% - 40px)
            width: 250px
            margin-bottom: 35px
        & textarea
            width: calc( 100% - 40px )
            height: 40vh
