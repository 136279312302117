@use '../utils/_variables' as * 

.dashboardHeader
    width: calc( 80% - var(--dashboard-menu-width) )
    height: $dashboardHeaderHeight
    position: fixed
    // left: $dashboardMenuWidth
    left: var(--dashboard-menu-width)
    background-color: var(--light-text-high)
    color: var(--light-background)
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    margin: 15px calc( 10% + var(--dashboard-menu-width) ) 0 10%
    padding: 0 20px
    border-radius: 10px
    transition: 0.2s ease-in-out
    @media (max-width: $mobileSize)
        width: calc( 100% - 40px )
        margin: 0
        left: 0
        border-radius: 0
    & nav
        & a
            // color: var(--dark-text-high)
            color: inherit
    & h1
        // color: var(--dark-text-high)
        color: inherit
        font-size: 24px
        @media (max-width: $tabletSize)
            font-size: 22px
        @media (max-width: $mobileSize)
            font-size: 20px
    &__user
        display: flex
        align-items: center
        gap: 10px
        // color: var(--dark-text-high)
        color: inherit
        &__logo
            width: 30px
            height: 30px
            border-radius: 50%
            background-color: var(--light-background)
            color: var(--dark-element)
            font-size: 25px
            display: flex
            align-items: center
            justify-content: center
            @media (max-width: $tabletSize)
                width: 25px 
                height: 25px
                font-size: 20px
            @media (max-width: $mobileSize)
                width: 20px
                height: 20px
                font-size: 16px
        &__name
            @media (max-width: $mobileSize)
                font-size: 16px
                display: none
