@use '../utils/_variables' as *

body
    margin: 0
    background-color: var(--background)
    color: var(--text-high)
    font-family: "Montserrat", sans-serif
    // &:has(.dashboard)
    //     background-color: linear-gradient(145deg, var(--background) 0%, var(--background-subtle) 50%, var(--background) 100%)
    @media (max-width: $mobileSize)
        scrollbar-width: none
        &::-webkit-scrollbar 
            display: none // To hide scroll bar on mobile version

h1, h2, h3, h4, h5, h6
    font-family: "Nunito", sans-serif
h1
    text-align: center
.mainContainer
    min-height: calc( 100vh - $headerHeight - $footerHeight )
    display: flex
    flex-direction: column
    align-items: center


.globalDashboardContainer
    display: flex
    flex-direction: row



a
    text-decoration: none

.danger
    color: var(--danger)
.success
    color: var(--success)
.warning
    color: var(--warning)
.info
    color: var(--info)

    
.modal
    display: none
    width: 100vw
    height: 100vh
    z-index: 1000
    position: fixed
    top: 0
    left: 0
    background-color: rgba(0,0,0,0.75)
    justify-content: center
    align-items: center
    
    &__content
        width: 50%
        height: 60vh
        background-color: var(--background)
        border-radius: 8px
        display: flex
        flex-direction: column
        align-items: center
        padding: 20px
        overflow: scroll
        @media (max-width: $tabletSize)
            position: fixed
            top: 0
            width: calc( 100vw - 40px )
            // height: calc( 100vh - 40px)
            height: calc( 100vh - 40px - var(--mobile-menu-height))
            border-radius: initial
            transition: 0.2s ease-in-out
    &__closeButton
        position: fixed
        align-self: end
        font-size: 25px
        &:hover
            cursor: pointer


@keyframes rotate 
    from 
        transform: rotate(0deg)
  
    to 
        transform: rotate(360deg)

.spinner
    // font-size: 30px
    height: 75px
    // transition: animation 4s
    animation: spin 4s linear infinite

@keyframes spin
  0% 
    transform: rotate(0deg)
    fill: var(--text-high)
    // animation-timing-function: ease-in
  50% 
    transform: rotate(360deg)
    fill: var(--background)
    // stroke: var(--text-high)
    // animation-timing-function: ease-in
  100% 
    transform: rotate(720deg)
    fill: var(--text-high)
    // animation-timing-function: ease-out