@use '../utils/_variables' as *

.btn
    width: fit-content
    display: flex
    align-items: center
    justify-content: center
    padding: 10px 60px
    border-radius: 10px
    font-weight: 500
    font-size: 16px
    border: none
    transition: 0.2s ease-in-out
    @media (max-width: $mobileSize)
        width: 230px
        padding: 10px 0
    &:hover
        cursor: pointer
    &--dark
        background-color: var(--text-high)
        color: var(--background)
        &:hover
            background-color: var(--text-low)
    &--light
        background-color: var(--element)
        color: var(--text-high)
        &:hover
            background-color: var(--element-hovered)