@use '../utils/_variables' as *

.dashboardMenu
    height: 100vh
    transition: 0.2s ease-in-out
    // height: calc( 100vh - $dashboardHeaderHeight )
    // width: $dashboardMenuWidth
    width: var(--dashboard-menu-width)
    background-color: var(--light-text-high)
    color: var(--light-background)
    position: fixed
    top: 0
    // bottom: 0
    display: flex
    flex-direction: column
    align-items: center
    // border-radius: 0 15px 0 0
    @media (max-width: $mobileSize)
        display: none
    &__toggleButton
        align-self: end
        position: absolute
        right: -30px
        top: 20px
        color: var(--light-text-high)
        font-size: 35px
        &:hover
            cursor: pointer
    &__title
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        flex-wrap: wrap
        gap: 10px
        margin-top: 30px
        &__icon
            font-size: 25px
        &__name 
            font-size: 25px
            text-align: center
            margin: 0
    &__nav
        margin: auto 0 
        display: flex
        flex-direction: column
        gap: 15px
        &__item
            font-size: 18px
            &:hover
                cursor: pointer
                font-weight: bold
    &__contact
        color: var(--background)
        display: flex
        flex-direction: column
        & svg
            font-size: 25px
        & p
            font-size: 18px

.dashboardMobileMenu
    display: none
    width: 100vw
    height: fit-content
    position: fixed
    top: calc( 100vh - 50px )
    // top: calc( 100vh - var(--mobile-menu-height) )
    left: 0
    border-top: 2px solid var(--text-high)
    transition: 0.2s ease-in-out
    z-index: 100
    background-color: var(--background)
    @media (max-width: $mobileSize)
        display: initial
    &--closed
        height: 50px
        display: flex
        align-items: center
        justify-content: center
    &--small
        width: 100%
        height: 50px
        display: flex
        justify-content: space-around
        align-items: center
        border-top: 2px solid var(--background)
        & svg
            font-size: 20px
    &--big
        height: calc( 100vh - 60px )
        background-color: var(--text-high)
        color: var(--background)
        display: flex
        flex-direction: column 
        align-items: center
        padding: 30px
        &__closeButton
            align-self: end
            font-size: 30px
        &__nav
            min-height: fit-content
            height: 50%
            margin: auto 0
            display: flex
            flex-direction: column
            align-items: center
            justify-content: space-around
            &__item
                display: flex
                flex-direction: row
                gap: 15px
                font-size: 20px
        &__contact
            color: var(--background)
            display: flex
            align-items: center
            gap: 15px
            font-size: 20px