@use '../utils/_variables' as *


.containerTest
    width: 900px
    max-width: 50vw
    margin: 30px auto
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    @media (max-width: $mobileSize)
        max-width: 95vw

.cloudyIcon--right
    font-size: 50px
    align-self: end
    position: relative
    right: -65px
    margin-bottom: 50px
    @media (max-width: $mobileSize)
        right: 15%
        top: 15px
// .cloudyIcon--left
//     color: var(--background-subtle)
//     font-size: 50px
//     align-self: start
//     position: relative
//     left: -50px
//     margin-bottom: 50px
.cloudyIcon--left
    height: 50px
    align-self: start
    position: relative
    left: -65px
    margin-bottom: 50px
    fill: var(--background-subtle)
    stroke: var(--text-high)
    stroke-width: 5
    @media (max-width: $mobileSize)
        left: 15%
        top: 15px


// .testContainer
//     // width: fit-content
//     // height: fit-content
//     // margin-bottom: 150px
//     padding: 15px 0px 0px 25px
//     border: 1px solid green
//     & p
//         // position: relative
//         // top: -50%
//         // color: var(--background)
//         color: red
// .testBubble 
//     // width: 100%
//     // height: 100%
//     // position: absolute
//     & path
//         fill: var(--text-high)
//     &--text
//         fill: var(--background)

.svgBubble