@use '../utils/_variables' as *

.ticketPreview
    position: fixed
    // overflow-x: scroll
    scrollbar-width: none
    -ms-overflow-style: none
    border-radius: 5px
    display: none
    flex-direction: column
    align-items: center
    justify-content: center
    cursor: grab
    background-color: rgba(0,0,0,0.5)
    &:active
        cursor: grabbing
    &__closeBtn
        // align-self: end
        font-size: 25px
        position: absolute
        top: -22px
        right: 5px
        align-self: end
        &:hover
            cursor: pointer
    &__container
        
        display: flex
        align-items: center
        // width: calc( 100% - 10px )
        width: 100%
        height: 100%
        &__img
            // object-fit: contain
            object-fit: contain
            width: 100%
            height: 100%
        &__previous
            position: relative
            // left: -25px
            font-size: 25px
            width: 25px
            height: 25px
            cursor: pointer
            color: var(--background)
        &__next
            position: relative
            // right: -25px
            font-size: 25px
            width: 25px
            height: 25px
            cursor: pointer
            color: var(--background)
    &__deleteBtn
        font-size: 20px
        position: absolute
        bottom: 10px
        // left: 4px
        // align-self: start
        &:hover
            cursor: pointer
