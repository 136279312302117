@use '../utils/_variables' as *

.accounts
    min-height: calc( 100vh - $dashboardHeaderHeight )
    display: flex
    flex-direction: column
    align-items: center
    padding-bottom: 15px
    // &__title
    //     text-align: center
    &__container
        display: flex
        justify-content: center
        flex-wrap: wrap
        gap: 50px
        margin: auto
        &__item
            transition: 0.2s ease-in-out
            width: 200px
            height: 100px
            display: flex
            align-items: center
            justify-content: center
            border-radius: 10px
            background-color: var(--element)
            border: 0.5px solid var(--border)
            &:hover
                border: 0.5px solid var(--border-hovered)
                background-color: var(--element-hovered)
                cursor: pointer
            &__plus
                width: 25px
                height: 25px
                padding: 5px
                font-weight: 100
.currentAccount
    display: flex
    flex-direction: column
    align-items: center
    @media (max-width: $mobileSize)
        flex-wrap: wrap
    &__balance
        width: 100%
        display: flex 
        justify-content: space-around
        margin: 20px 0
        font-size: 18px
        @media (max-width: $mobileSize)
            flex-direction: column
            align-items: center
            justify-content: center
            flex-wrap: wrap
            & p
                margin: 5px
    &__exitBtn
        position: fixed
        font-size: 20px
        align-self: start
        transition: 0.1s ease-in-out
        @media (max-width: $mobileSize)
            position: sticky
            // top: calc( $dashboardHeaderHeight + 15px ) 
            top: 15px 
            left: calc(25px - 2vw)
        &:hover
            cursor: pointer
            font-size: 23px
            margin: -1.5px
    &__ticketBtn
        position: fixed
        // top: calc( $dashboardHeaderHeight + 65px )
        font-size: 20px
        // right: 37px
        right: 65px
        align-self: end
        transition: 0.1s ease-in-out
        @media (max-width: $mobileSize)
            display: none
        &:hover
            color: var(--text-low)
            cursor: pointer
            font-size: 23px
            margin: -1.5px
    &__settings
        position: fixed
        right: 35px
        font-size: 20px
        transition: 0.1s ease-in-out
        @media ( max-width: $mobileSize )
            top: calc( $dashboardHeaderHeight + 15px )
            right: 25px
            
        &:hover
            cursor: pointer
            color: var(--text-low)
            font-size: 23px
            margin: -1.5px
    
        &__menu
            z-index: 1000
            height: 0
            opacity: 0
            transition: height 0.2s ease-in-out, opacity 0.1s 0.1s
            position: fixed
            background-color: var(--background)
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px
            border-radius: 5px
            display: none
            flex-direction: column 
            justify-content: center
            padding: 5px 0
            overflow: hidden
            &__closeButton
                display: none
                position: fixed
                top: 25px
                right: 25px
                font-size: 25px
                @media (max-width: $mobileSize)
                    display: initial
            @media (max-width: $mobileSize)
                width: 100vw
                height: calc( 100vh - var(--mobile-menu-height))
                border-radius: 0
                justify-content: start
                padding-top: 40px
            &__button
                padding: 10px 20px
                display: flex
                gap: 20px
                font-size: 15px
                @media (max-width: $mobileSize)
                    font-size: 20px
                &:hover
                    cursor: pointer
                    background-color: var(--element)
                & svg 
                    width: 20px
                &--export
                    width: 100%
                    display: flex
                    gap: 20px
                    color: var(--text-high)
            &__separator
                height: 1px
                width: 100%
                background-color: var(--solid)
                margin: 10px 0

    &__title
        text-align: center
    &__table
        width: 100%
        border-collapse: collapse
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px
        border-radius: 5px
        overflow: hidden
        // background-color: var(--element-selected)
        @media (max-width: $mobileSize)
            display: none
        & tr
            // border-bottom: 0.25px solid var(--border)
            border-bottom: 0.5px solid rgba(0,0,0,0.1)
            cursor: default
            & th 
                text-align: left
                height: 45px
                font-weight: normal
                padding: 0 3px
                &:first-child
                    padding-left: 15px
            & td
                height: 45px
                &:first-child
                    padding-left: 15px
        & tr:last-child
            border-bottom: none
        &__head
            // background-color: var(--element-selected)
            border-bottom: 0.5px solid rgba(0,0,0,0.1)
        &__line:nth-child(even)
            // background-color: var(--background-subtle)
            background-color: var(--background)
            // background-color: var(--element-hovered)
        &__line
            // background-color: var(--element)
            background-color: var(--background-subtle)
            &:hover
                // background-color: var(--background-subtle)
                background-color: var(--element-hovered)
                border-bottom: 0.5px solid var(--border-hovered)
            &--active
                background-color: var(--element-selected)
                border-bottom: 0.5px solid var(--border-selected)
            &__multipleFields
        &__date
            font-size: 14px
        &__type
            font-size: 12px
        &__category
            font-size: 14px
            font-weight: 500
        &__subcategory
            font-size: 12px
        &__amount
            max-width: 125px
            &--debit
                text-align: left
            &--credit
                width: 100%
                text-align: right
        &__checkbox
            font-size: 18px
            &:hover
                cursor: pointer
        &__settings
            display: flex
            align-items: center
            &:hover
                cursor: pointer
            &__icon
                height: calc( 100% - 10px )
                display: flex
                align-items: center
                justify-content: center
                padding: 5px
                font-size: 18px
            &__menu
                width: 0
                overflow: hidden
                transition: width 0.2s ease-in-out
                display: flex
                gap: 3px
                position: fixed
                cursor: default
                border-radius: 3px
                background-color: var(--background)
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px
                &__button
                    padding: 5px
                    width: 35px
                    &:hover
                        cursor: pointer
    &__mobileContainer
        display: none
        width: 100%
        @media (max-width: $mobileSize)
            display: flex
            flex-direction: column
            align-items: center
            gap: 15px
        &__item
            width: calc( 100% - 30px )
            padding: 10px
            display: flex
            flex-direction: column
            gap: 5px
            background-color: var(--background-subtle)
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px
            &__header
                display: flex
                align-items: center
                justify-content: space-between
                &__title
                    font-size: 18px
                    margin: 0
                &__settingButton
            &__body
                display: flex
                &__content
                    width: 75%
                    display: flex
                    flex-direction: column
                    gap: 10px
                    &__type
                    &__middle
                        // min-height: 50px
                        display: flex

                        &__status
                            width: 15%
                        &__infos
                            display: flex
                            flex-direction: column
                            justify-content: space-between
                            &__date
                            &__description
                    &__category
                &__amount
                    min-width: 25%
                    display: flex
                    justify-content: center
                    align-items: center
                    font-size: 18px
                    font-weight: 600
            // &__menu
            //     width: 0
            //     overflow: hidden
            //     transition: width 0.2s ease-in-out
            //     display: flex
            //     gap: 3px
            //     position: fixed
            //     cursor: default
            //     border-radius: 3px
            //     background-color: var(--background)
            //     box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px
            //     &__button
            //         padding: 5px
            //         width: 35px
            //         &:hover
            //             cursor: pointer
            &__menu
                width: 0
                height: 0
                overflow: hidden
                transition: 0.2s ease-in-out
                display: flex
                flex-direction: column
                justify-content: space-between
                align-items: center
                position: fixed
                cursor: default
                border-radius: 50%
                // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px
                &__center
                    width: 40px
                    height: 40px
                    // border-radius: 50%
                    // background-color: var(--element)
                    // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px inset
                    background-color: var(--text-high)
                    border-radius: 50%
                    display: flex
                    align-items: center
                    justify-content: center
                    & svg
                        font-size: 20px
                        color: var(--background)
                &__button
                    padding: 5px
                    // width: 15px
                    // height: 15px
                    // background-color: var(--text-high)
                    border-radius: 50%
                    // color: var(--background)
    &__newMobileTransactionButton
        position: fixed
        right: 25px
        bottom: calc( var(--mobile-menu-height) + 25px)
        transition: 0.2s ease-in-out
        & svg
            font-size: 35px
    &__newTransactionButton
        position: fixed
        right: 25px
        bottom: 25px
        transition: 0.2s ease-in-out
        background-color: var(--background)
        cursor: pointer
        & svg
            font-size: 40px
        &:hover
            color: var(--text-low)



    // &__newLine
    //     width: calc( 100vw - $dashboardMenuWidth - 120px )
    //     position: fixed
    //     bottom: 15px
    //     padding: 15px 10px
    //     display: grid
    //     grid-template-columns: 1fr 1fr 1fr 1fr
    //     gap: 5px
    //     flex-direction: row
    //     align-items: center
    //     justify-content: space-between
    //     flex-wrap: wrap
    //     background-color: var(--text-high)
    //     border-radius: 8px
    //     & button
    //         grid-row: 1/3
    //         grid-column: 4
            
    &__newLine
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px
        // width: calc( 100vw - $dashboardMenuWidth - 120px )
        position: fixed
        // bottom: 15px
        padding: 10px
        display: grid
        grid-template-columns: 1fr 1fr 
        gap: 10px
        // flex-direction: row
        // align-items: center
        // justify-content: space-between
        // flex-wrap: wrap
        // background-color: var(--text-high)
        background-color: var(--light-text-high)
        border-radius: 5px
        &__closeButton
            display: none
            position: fixed
            top: 25px
            right: 25px
            font-size: 30px
            color: var(--background)
        &__title
            display: none
            color: var(--background)
        & button
            grid-column: 1/3
        @media (max-width: $mobileSize)
            display: none
            width: 100vw
            // height: 100vh
            height: calc( 100vh - var(--mobile-menu-height))
            top: 0
            left: 0
            justify-content: center
            align-items: center
            gap: 15px
            overflow-y: scroll
            padding: 0
            border-radius: 0
            transition: 0.2s ease-in-out
            &__closeButton
                display: initial
            &__title
                display: initial
            & input
                width: calc( 95vw - 40px )
                padding: 10px 20px
                // padding: 0
                margin: 0
            & select
                width: 95vw
                height: 44px
                padding: 10px 20px
                margin: 0
            & button
                padding: 10px 40px
